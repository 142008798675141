import React from "react";
import { toast } from "react-hot-toast";
import { ReactComponent as BlueTick } from "../../assets/images/blue-tick.svg";
import { ReactComponent as Warn } from "../../assets/images/warn.svg";

const ShowToast = ({ message, type, duration = "", isWarning = false }) => {
  const toastStyles = {
    success: {
      borderRadius: "10px",
      border: "1px solid #5E66D6",
      background: "#F2F3FF",
      color: "#131426",
      fontFamily: "Poppins",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      letterSpacing: "-0.3px",
      marginTop: "50px",
      // minHeight: "66px",
      // minWidth: "200px",
    },
    error: {
      borderRadius: "10px",
      border: "1px solid #CC0000",
      background: "#FFD2D2",
      color: "#CC0000",
      fontFamily: "Poppins",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      letterSpacing: "-0.3px",
      marginTop: "50px",
      // minHeight: "66px",
    },
  };

  const style = toastStyles[type] || {};
  let toastId;
  if (type === "success") {
    toast.dismiss(toastId);
    toast.success(message, {
      style: { ...style },
      icon: isWarning ? <Warn width={"30px"} /> : <BlueTick width={"30px"} />,
    });
  } else if (type === "error") {
    toast.dismiss(toastId);
    toast.error(message, {
      style: { ...style },
      duration: duration,
    });
  }

  return null;
};

export default ShowToast;
